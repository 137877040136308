.introduction {
    padding: clamp(30rem, 55vh, 55vh) 0 3vh 0;
    background-color: #fff;
}

.rowWrapper {
    display: flex;
    flex-direction: row;
    background-color: var(--background-color);
    padding: 7vh 5vw;
}

.introTextContainer {
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.introTextContainer h1 {
    font-weight: bold;
    color: var(--primary);
    text-align: center;
}

.greyIntroTextContainer {
    color: var(--grey-color);
    text-align: center;
    line-height: 27px
}

.introImg {
    width: 35vw;
    height: 65vh;
    object-fit: cover;
    margin: 0 3rem 0 0;
}

.introHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    padding: 2vh 0;
}

.cursiveText {
    font-family: Alex Brush, cursive;
    font-size: 2.5rem;
    margin: 0
}

@media (max-width: 768px) {

    .introduction {
        padding: clamp(30rem, 55vh, 55vh) 0 0 0;
    }

    .rowWrapper {
        flex-direction: column;
        padding: 0 0 3vh 0;
    }

    .introHeader {
        padding: 1vh 0;
    }

    .introTextContainer {
        width: 85vw;
        align-items: center;
        align-self: center;
    }

    .introTextContainer h1 {
        text-align: center;
        font-size: 1rem;
    }

    .introTextContainer h3 {
        text-align: center;
    }

    .cursiveText {
        font-family: Alex Brush, cursive;
        font-size: 2.3rem;
        margin: 0;
        text-align: center;
    }

    .greyIntroTextContainer {
        color: var(--grey-color);
        text-align: left;
        line-height: 20px;
        font-size: 1rem;
    }

    .introImg {
        width: 85vw;
        height: 100vw;
        object-fit: cover;
        margin: 3vh 0 0 0;
        border-radius: 0 0 10px 0;
        align-self: center;
    }
}
