.wrapper {
  padding: 0 0 3vh 0;
}

.wrapper h1 {
  margin: 0;
  text-align: center;
  font-family: Alex Brush, cursive;
  background-color: #fff;
  color: var(--primary);
  font-size: 5rem;
}

.pricingImage {
  display: block;
  margin: 0 auto;
  width: 70vw;
  object-fit: cover;
}

.container {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 0 3vw;
}

.container h2 {
  text-align: center;
}

.servicesContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 clamp(9vw, 25vw, 23vw);
}

.serviceItem {
  min-width: 7rem;
  min-height: 10rem;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  padding: 0 0.5vw;
  margin: 4vh 1vw;
  max-width: 25vw;

  &:hover h4.change-color-on-hover {
    color: var(--grey-color);
    transition: color 0.2s ease-in-out;
  }
}

.serviceItem:hover {
  /* border: 1px solid #5a5e65;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2), -2px -2px 4px rgba(255, 255, 255, 0.2); */
  /* animation: jump 0.5s ease; */
}

.serviceItem:hover .roundDiv {
  animation: jump 0.3s ease;
}

.roundDiv {
  width: 9vw;
  height: 9vw;
  background-color: var(--primary);
  border-radius: 50%;
  border-top-left-radius: 130px 140px;
  position: relative;
  transition: transform 0.3s ease;
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.serviceItem h3 {
  white-space: pre-wrap;
  text-align: center;
  color: var(--primary);
  margin: 2vh 0 0 0;
}

.serviceItem h4 {
  white-space: pre-wrap;
  text-align: center;
  color: var(--primary);
}

.serviceItem img {
  width: clamp(5vw, 6vw, 7vw);
  height: clamp(5vw, 6vw, 7vw);
}

.menuPricing {
  border: 2px solid var(--blue-color);
  margin: 3vh 5vw 0;
  padding: 1vh 2vw;
}

.pricing {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.pricing h3 {
  color: var(--grey-color);
  width: 23%;
}

.menuTitle {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.menuTitle h2 {
  width: 23%;
  color: var(--primary);
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.rowItem {
  flex-direction: row;
  display: flex;
}

.pricingItem2 {
  flex-direction: row;
  display: flex;
  width: 100%;
}

.pricingItem2 h3 {
  color: var(--grey-color);
  width: 100%;
}

@media (max-width: 768px) {
  .wrapper {
    padding: 0 0 0 0;
  }

  .container {
    flex-direction: column;
    padding: 0 0;
    justify-content: center;
    align-items: center;
  }

  .container h2 {
    text-align: center;
  }

  .servicesContainer {
    margin: 1vh 3vw;
  }

  .pricingImage {
    width: 100vw;
  }

  .roundDiv {
    width: 20vw;
    height: 20vw;
    border-radius: 50%;
    border-top-left-radius: 100px 100px;
  }

  .serviceItem {
    min-height: 8rem;
    padding: 0.7vh 20vw;
    margin: 0;
    max-width: none;
  }

  .serviceItem img {
    width: 15vw;
    height: 15vw;
  }

  .serviceItem h3 {
    margin: 1vh 0 0 0;
    font-size: 1rem;
  }

  .serviceItem h4 {
    white-space: pre-wrap;
    font-size: 0.8rem;
  }

  .menuPricing {
    border: 4px solid var(--blue-color);
    margin: 0vh 0vw 2vh 0;
    padding: 1vh 2vw;
  }

  .pricing {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }

  .pricing h3 {
    color: var(--grey-color);
    width: 23%;
  }

  .noMargin {
    margin: 0;
  }
}
