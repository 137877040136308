.introHeader {
  display: flex;
  padding: 1.5vh clamp(0.8rem, 3vw, 3rem);
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--background-color);
}

.introHeader h4 {
  color: var(--primary);
  font-weight: lighter;
  padding: 0 0;
  word-spacing: 0;
  margin: 0 1.6vw 0 0;
  cursor: pointer;
  text-align: center;
}

.introHeader h4:hover {
  color: var(--grey-color);
  transition: color 0.5s ease-in-out;
  word-spacing: 1px;
}

.introLogo {
  width: clamp(2rem, 5vw, 3rem);
  height: clamp(2rem, 5vw, 3rem);
  object-fit: cover;
}

.introHeader button {
  background-color: transparent;
  border: 0;
}

.menuItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bookNow {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.bookNowA {
  margin-right: 1rem;
  color: var(--primary);
}

.clickableLogo {
  cursor: pointer;
}

/*SIZE FOR SMALL DEVICES*/
@media (max-width: 768px) {
  .introHeader {
    padding: 2vh 4vw;
    max-width: 100%;
    justify-content: space-between;
  }

  .introHeader h4 {
    color: #ffffff;
    font-weight: lighter;
    padding: 0 0;
    word-spacing: 0;
    margin: 0 0;
    cursor: pointer;
    text-align: center;
    font-size: clamp(1rem, 1.4vw, 1.8rem);
  }

  .introLogo {
    width: 11vw;
    height: 11vw;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .bookNowA {
    display: none;
  }

  .bookNowBtnHeader {
    padding: 0 !important;
    min-height: 2rem !important;
    min-width: 5rem !important;
  }
}
