.contacts {
    display: flex;
    background-color: var(--primary);
    flex-direction: row;
    padding: 0 0 0 var(--margin-horizontal);
    justify-content: space-between;
}

.contacts h1 {
    white-space: pre-wrap;
    text-align: left;
    margin: 2% 0 20px;
    font-weight: bolder;
    color: #fff;
}

.contacts a {
    color: #fff;
    font-weight:600;
}

.contacts a:hover {
    transition: color 0.5s ease-in-out;
    color: black;
}

.contactsContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 1%;
    padding: 0 0 5%;
}

.contactsContainerImg {
    width: 35px;
    height: 35px;
    margin-right: 15px;
    margin-top: 2vh;
}

.iframe {
    height: auto;
    width: 70%;
    border: 0;
}

.bookNowBtn{
    min-height: 3rem;
    margin: 2vh 0 0 0;
    background-color: #fff;
}

.bookNowBtn h3{
    color: var(--primary)
}

@media (max-width: 768px) {
    .contacts {
        flex-direction: column;
        padding: 0;
    }

    .contactsContainer {
        margin-left: var(--margin-horizontal);
    }

    .map {
        width: 100%;
        height: 40vh;
        margin-top: 1vh;
    }

    .iframe {
        height: 40vh;
        width: 100%;
    }
}
