.primaryBanner {
    display: flex;
    justify-content: center;
}

.primaryBannerImage {
    width: 100vw;
    height: 55vh;
    min-height: 30rem;
    object-fit: cover;
    position: absolute;
}

.primaryBanner h2 {
    background-color: transparent;
    font-weight: 600;
}

.textContainer {
    position: absolute;
    top: clamp(18rem, 60%, 60%);
    left: 50%;
    transform: translate(-50%, -100%);
    text-align: center;
    padding: 20px;
    border-radius: 5px;
}

.textContainer h2 {
    color: #fff;
    margin-bottom: 1vh;
}

@media (max-width: 768px) {
    .textContainer {
        top: clamp(14rem, 65%, 90%);
    }
}
